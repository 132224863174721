import React , {useRef, useEffect} from "react";
import { BrowserRouter as  Route, Switch } from "react-router-dom";
import { Link } from "react-router-dom";
import ProductSelection from "./ProductSelection";
import CheckoutPage from "./CheckoutPage";
import Header from "./Header";
import CustomVideoPlayer from "./CustomVideoPlayer";
import GalleryVideo from "./GalleryVideo";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import SplitType from 'split-type'

// Images
import bannerLogo from "../assets/images/banner_logo.svg";
import galleryImgTwo from "../assets/images/img2.jpg";
import galleryImgThree from "../assets/images/img3.jpg";
import galleryImgFour from "../assets/images/img4.jpg";


function Home() {
  const prc = [
    { label: "INTRODUCTION VIDEO", cost: 150, checked: false },
    { label: "ANIMATED LOGO", cost: 100, checked: false },
    { label: "360 TOUR", cost: 150, checked: false },
    // { label: "SOCIAL MEDIA INFO VIDEO", cost: 150, checked: false },
  ];

  //==================================================== gsap Animation ====================================================
  gsap.registerPlugin(ScrollTrigger) 

  useGSAP(() => {
    gsap.from(".logo_block", {y: -100, opacity: 0,duration: 1, ease: "power3.out"});

    const fadeUp = gsap.utils.toArray('.fade_up');
    fadeUp.forEach(box => {
      gsap.from(box, { 
        y: 50,
        opacity:0,
        duration:1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: box,
          start: "center 85%",
        }
      })
    });


    const fadeRight = gsap.utils.toArray('.fade_right');
    fadeRight.forEach(box => {
      gsap.from(box, { 
        x: -50,
        opacity:0,
        duration:1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: box,
          start: "center 85%",
          // markers:true
        }
      })
    });

    const splitText = gsap.utils.toArray('.split_text');
    splitText.forEach(box => {
      const ourText = new SplitType(box, { types: 'chars' }); // Use 'box' instead of 'splitText'
      const charsText = ourText.chars;
      gsap.set(charsText, { opacity: 0, y: 20 });
      gsap.to(charsText, {
        y: 0,
        opacity: 1,
        stagger: '0.02', // Use a string for stagger value
        duration: .5,
        ease: 'power4.out',
        scrollTrigger: {
          trigger: box,
          start: "center 85%",
          // markers: true,
        },
      });
    });
  }, null);

  const videoBlock = useRef();
  const ourWork = useRef();
  const addonsBlock = useRef();


    useGSAP(() => {

      gsap.from(".gallery_block .img", {
        duration:1, 
        stagger:"0.02", 
        opacity:0,
        y:20,
        scrollTrigger: {
          trigger: ourWork.current,
          start: "center 85%",
          // markers:true
        }
      })
     
    }, { scope: ourWork });
  
  useGSAP(() => {
    const addTl = gsap.timeline({
      scrollTrigger: {
        trigger: addonsBlock.current,
        start: "center 85%",
        // markers:true
      }
    })
  
    addTl.from(".addons_block li", {
      x:-60,
      stagger: .1,
      duration: 1,
      opacity:0,
      ease: 'power2.out',
    })
    .from(".addons_block .cta_pink", {y:10, opacity:0, duration: 1, ease: 'power2.out'}, "=-.8")

  }, { scope: addonsBlock });



  const titleAnimation = (titleSelector) => {
    const titleBox = document.querySelector(titleSelector);

    if (!titleBox) {
      console.error(`Title box not found for selector: ${titleSelector}`);
      return null;
    }

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: titleBox,
        start: 'top 80%',
        end: 'bottom center',
        // markers: true, 
      },
    });

    //  Select only the child elements within the titleBox
    const subBox = titleBox.querySelector('.box', );
    const text = titleBox.querySelector('.text', );

    if (!subBox || !text) {
      console.error(`Sub box or text not found within title box: ${titleSelector}`);
      return null;
    }

    // gsap.set([subBox, text], { opacity: 1, scale: 1 });
    // gsap.set([text], { x:1 });

    tl.to(subBox, { opacity: 1, scaleY: 1, ease: 'power1.out' })
      .to(text, { opacity: 1, x: 1, ease: 'power1.out' }, "=-.2");
  
    return tl;
  };

  useEffect(() => {
    // Call the function for each title by passing the title selector
    titleAnimation('.titleBoxOne');
    titleAnimation('.titleBoxTwo');
    titleAnimation('.titleBoxThree');
    titleAnimation('.titleBoxFour');
    titleAnimation('.titleBoxFive');
    // Add more titles if needed
    
  }, []);
  
  const videoRef = useRef();

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: videoElement,
          start: 'top center', // Change the trigger start position as needed
          end: '80% 20%', // Specify when the animation should reverse
          toggleActions: 'play pause none reverse',
          // markers:true,
        },
      });

      tl.to(videoElement, { duration: 1, opacity: 1, ease: 'power1.inOut' });
      tl.to(videoElement, { duration: videoElement.duration || 1, play: 'auto', ease: 'none' });
      // tl.to(videoElement, { duration: 0.1, pause: 0 }); // Pause the video when leaving the trigger area

      return () => {
        // Cleanup on component unmount
        tl.kill();
      };
    }
  }, []);


  return (
    <div className="main" >
      <Header />
      {/* -------------------------------- banner section -------------------------------- */}
      <section className="main_banner">
        <div className="logo_block">
          <img src={bannerLogo} alt="bannerLogo" />
        </div>
      </section>

      {/* -------------------------------- video section -------------------------------- */}
      <section className="video_block" ref={videoBlock}>
        <div className="bg">
          <div className="title_box yellow titleBoxOne">
            <div className="sub_box">
              <div className="box"></div>
              <div className="text">
                <h3 className="under_line">PROFESSIONAL VIDEO AND PHOTOS</h3>
                <h4>AT A FRACTION OF THE PRICE</h4>
              </div>
            </div>
          </div>
          <div className="title_box green titleBoxTwo">
            <div className="sub_box">
              <div className="box"></div>
              <div className="text">
                <h3>HOW WE WORK</h3>
              </div>
            </div>
          </div>
        </div>

        <div>
          <CustomVideoPlayer />
        </div>
      </section>

      {/* -------------------------------- Our Work -------------------------------- */}
      <div className="bg_block">
        <section id="our_work" className="our_work" ref={ourWork} >
          <div id="our-work" className="title_box yellow titleBoxThree">
            <div className="sub_box">
              <div className="box"></div>
              <div className="text">
                <h3 className="under_line">OUR WORK</h3>
                <h4>WE PRIDE OURSELVES ON DELIVERING HIGH QUALITY PHOTOS AND VIDEO WITHIN 48 HOURS</h4>
              </div>
            </div>
          </div>
          <div className="gallery_block">
            <div className="img img1">
              <GalleryVideo />
            </div>
            <div className="img_row2">
              <div className="img">
                <img src={galleryImgTwo} alt="galleryImgTwo" />
              </div>
              <div className="img">
                <img src={galleryImgThree} alt="galleryImgThree" />
              </div>
              <div className="img">
                <img src={galleryImgFour} alt="galleryImgFour" />
              </div>
            </div>
          </div>
        </section>
          <ProductSelection />
        <Switch>
          <Route path="/checkout" component={CheckoutPage} />
        </Switch>
        <section className="addons_block" ref={addonsBlock}>
          <div className="wraper">
            <div className="title_box pink titleBoxFour">
              <div className="sub_box">
                <div className="box"></div>
                <div className="text">
                  <h3 className="under_line">CREDITS NEVER EXPIRE</h3>
                  <h4>USE YOUR CREDITs WHEN YOU NEED</h4>
                </div>
              </div>
            </div>
            <div className="title_box green titleBoxFive">
              <div className="sub_box">
                <div className="box"></div>
                <div className="text">
                    <h3 className="under_line">step 3 - CHOOSE YOUR ADD-ONS</h3>
                </div>
              </div>
            </div>
            <ul>
              {" "}
              {prc &&
                prc.map((item, index) => (
                  <li key={index}>
                    <span className="label">{item.label}</span>
                    <span className="cost">${item.cost}</span>
                  </li>
                ))}
            </ul>
            <Link to="/checkout">
              <button className="cta cta_pink">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 25" > <path fill="#000" d="m25.036 5.162-.445-.02L9.46 4.46l-.108 2.354 14.494.653-2.462 7.694H9.668L7.938 4.878 7.483 2.16l-.658-.264L2.1 0l-.851 2.191 4.168 1.674 2.037 12.11.26 1.542h1.104l-.128.402-.198.617-.754 2.354.007.002h1.513a2.483 2.483 0 0 0-.614 1.64c0 1.363 1.087 2.468 2.427 2.468s2.428-1.105 2.428-2.468c0-.63-.232-1.204-.614-1.64h5.268a2.483 2.483 0 0 0-.614 1.64c0 1.363 1.087 2.468 2.427 2.468s2.427-1.105 2.427-2.468c0-.63-.232-1.204-.613-1.64h1.396v-2.356H10.93l.327-1.019h11.81l.755-2.356 2.428-7.586.05-.154.631-1.974.064-.197-1.958-.088Zm-5.07 18.469a1.09 1.09 0 0 1-1.08-1.099 1.09 1.09 0 0 1 1.08-1.098c.597 0 1.08.492 1.08 1.098a1.09 1.09 0 0 1-1.08 1.099Zm-8.895-2.197c.597 0 1.08.492 1.08 1.098a1.09 1.09 0 0 1-1.08 1.099 1.09 1.09 0 0 1-1.08-1.099 1.09 1.09 0 0 1 1.08-1.098Z" /> </svg>
                PURCHASE NOW
              </button>
            </Link>
          </div>
        </section>
        <section className="faq_block" style={{display:"none"}}> 
          <div>
            <h3 className="title">FAQ SECTION</h3>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
