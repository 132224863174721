import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger) 


const CustomVideoPlayer = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    gsap.to(videoElement, {
       scrollTrigger: {
          trigger: videoElement,
          start: 'top center',
          end: 'bottom center',
          toggleActions: 'play none none none',
          // markers:true,
       },
       opacity: 1, 
    });
 }, []);

  return (
    <div ref={videoRef} className="video-wrapper" style={{ position: 'relative', width: '100%', height: 'auto' }}>
      <iframe 
        src="https://www.youtube.com/embed/e2FRV7ta9u0?rel=0&autoplay=1&mute=1&loop=1" 
        title="Boven Media Inc." 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowfullscreen>
      </iframe>
      <p className='note'>(Note: Click on 
        <span><svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 90 90"> <path d="M42.261 11.565c-6.234 0-4.349 10.548-25.386 16.465-5.816 1.632-8.75 2.863-8.75 8.916v16.143c0 6.053 2.934 7.284 8.75 8.914 21.037 5.918 19.151 16.46 25.386 16.46h2.708c2.849 0 5.162-2.309 5.162-5.161V16.731c0-2.851-2.313-5.166-5.162-5.166zM60.934 28.068c-3.094.036-5.016 3.373-3.5 6.073 3.629 6.695 3.629 15.048 0 21.748-1.141 1.987-.422 4.521 1.588 5.615 2.01 1.091 4.525.309 5.568-1.731 4.969-9.165 4.969-20.359 0-29.526-.715-1.355-2.125-2.201-3.656-2.179z"/> <path d="M72.031 17.718c-3.275.15-5.035 3.92-3.051 6.532 3.906 5.319 6.266 12.863 6.266 20.762S72.887 60.46 68.98 65.774c-1.34 1.814-.953 4.374.859 5.708 1.816 1.335 4.369.944 5.703-.875 5.119-6.977 7.848-16.106 7.848-25.595 0-9.485-2.729-18.613-7.848-25.59-.808-1.131-2.124-1.772-3.511-1.704z"/> </svg> </span>
        Icon for sound)
      </p>
    </div>
  );
};

export default CustomVideoPlayer;
