import { useState } from "react";
const packages = [
  {
    id: 1,
    name: "Package 1",
    credits: "one CREDIT",
    monthlyPrice: 850,
    oneTimePayment: 850,
    title: "Package 1: One CREDIT",
    packageNumber: 1,
  },
  {
    id: 2,
    name: "Package 2",
    monthlyPrice: 700,
    oneTimePayment: 1200,
    title: "PACKAGE 2: TWO CREDITS",
    credits: "two credits",
    creditAmount: 1400,
    monthTime: "(2 month term totalling $1400)",
    packageNumber: 2,
  },
  {
    id: 3,
    name: "Package 3",
    monthlyPrice: 650,
    oneTimePayment: 2200,
    title: "PACKAGE 3: FOUR CREDITS",
    credits: "FOUR CREDITS",
    creditAmount: 2600,
    monthTime: "(4 month term totalling $2600))",
    packageNumber: 4,
  },
  {
    id: 4,
    name: "Package 4",
    monthlyPrice: 600,
    oneTimePayment: 3000,
    title: "PACKAGE 4: SIX CREDITS",
    credits: "SIX CREDITS",
    creditAmount: 3600,
    monthTime: "(6 month term totalling $3600)",
    packageNumber: 6,
  },
  {
    id: 5,
    name: "Package 5",
    monthlyPrice: 550,
    oneTimePayment: 3600,
    title: "PACKAGE 5: EIGHT CREDITS",
    credits: "EIGHT CREDITS",
    creditAmount: 4400,
    monthTime: "(8 month term totalLing $4400)",
    packageNumber: 8,
  },
];

const videoSelection = [
  {
    id: 1,
    title: "PROPERTY VIDEO",
    credit: "ONE CREDIT INCLUDES",
    points: [
      (
        <>
          A 2-3 MIN WALK THROUGH VIDEO <br />
          <span>(2 HOURS ON SITE)</span>
        </>
      ), 
      "40 PROFESSIONALLY EDITED PHOTOS",
      "AERIAL DRONE FOOTAGE",
      "A 60 SECOND SOCIAL MEDIA REEL",
    ],
  },
  {
    id: 2,
    title: "Realtor hosted VIDEO",
    credit: "ONE CREDIT INCLUDES",
    points: [
      (
        <>
          Two 60 second realtor hosted social media videos <br />
          <span>(2 HOURS ON SITE)</span>
        </>
      ),  
      "AERIAL DRONE FOOTAGE"
    ],
  },
  
];
export const useAppData = () => {
  // GET appData DATA STORE IN LOCAL STORAGE
  const appDataInitialState = {
    selectedPlan: packages[0],
    packages: packages,
    selectedType: "monthly",
    selectedVideo: videoSelection[0],
    videoList: videoSelection,
    priceAddsOn: [
      { label: "INTRODUCTION VIDEO", cost: 150, checked: false },
      { label: "ANIMATED LOGO", cost: 100, checked: false },
      { label: "360 TOUR", cost: 150, checked: false },
    ],
    total: 0
  };
  const [appData, setAppData] = useState(appDataInitialState);
  //   useEffect(() => {
  //     localStorage.setItem("app_data", JSON.stringify(appData));
  //   }, [appData]);
  // return { appData, setAppData, appData:  data["ATMIYA"]}
  return { appData, setAppData };
};
