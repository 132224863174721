import React, { useState, useEffect, useRef } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { gsap, Power3 } from "gsap";
import { useGSAP } from "@gsap/react";


//Images
import pattern from "../assets/images/header-pattern.svg";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);

    // Use GSAP to animate the menu ====================================================
    if (!isOpen) {
      gsap.to(".menu", { duration: 0.5, x: 0, ease: Power3.easeOut });
      gsap.to(".bar1", { duration: 0.5, rotate: 45, y: 7, background: "#fbb040" });
      gsap.to(".bar2", { duration: 0.5, opacity: 0 });
      gsap.to(".bar3", {
        duration: 0.5,
        rotate: -45,
        y: -7,
        background: "#fbb040",
      });

      // Stagger the links in
      gsap.from(".nav-link", {
        duration: 0.5,
        x: -50,
        opacity: 0,
        stagger: 0.1,
        ease: Power3.easeOut,
      });
    } else {
      gsap.to(".menu", { duration: 0.5, x: "-100%", ease: Power3.easeOut });
      gsap.to(".bar1", { duration: 0.5, rotate: 0, y: 0, background: "#da1c5c" });
      gsap.to(".bar2", { duration: 0.5, opacity: 1 });
      gsap.to(".bar3", { duration: 0.5, rotate: 0, y: 0, background: "#da1c5c" });
    }
  };



  useEffect(() => {
    // Initial animation to hide the menu
    gsap.to(".menu", { duration: 0, x: '-100%' });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.innerWidth <= 768 || window.scrollY > 200);
    };

    const handleResize = () => {
      setScrolled(window.innerWidth <= 768);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    // Initial setup
    handleScroll();
    handleResize();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //==================================================== gsap Animation ====================================================
  const container = useRef();

  useGSAP(() => {
    gsap.from(".navbar", {y: -100});
  
  }, { scope: container });

  return (
    <header  ref={container}>
      <nav className="navbar xs_hide">
        <div className="menu_block">
          <div className="links-container">
            
            <Link to="/">
              <div className="logo">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 82 50"> {" "} <g clipPath="url(#a)"> {" "} <path d="M81.817 19.744 54.525 0 40.907 9.85 27.292 0 0 19.744c.016 7.75 0 30.255 0 30.255h82s-.151-22.526-.183-30.255Zm-33.941 3.374v20.154H33.94V23.118l6.968-5.04 6.968 5.04ZM6.709 43.272V23.118L27.292 8.227l7.93 5.737-7.99 5.78v23.528H6.71Zm47.875 0V19.744l-7.989-5.78 7.93-5.737 20.584 14.89v20.155H54.584Z" />{" "} </g>{" "} <defs> {" "} <clipPath id="a"> {" "} <path d="M0 0h82v50H0z" />{" "} </clipPath>{" "} </defs> </svg>
              </div>
            </Link>
            <div className="link_block">
              <Link to={"/#our_work"}>Our Work</Link>
              <Link to={"/#our_packages"}>Our packages</Link>
              {/* <a href="#work">Our Work</a> */}
            </div>
            <div className="social_link_block">
              <a className="social_icon" href="https://www.instagram.com/bovenmedia/" target="_blank" rel="noopener noreferrer">
                <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 35 35"> <path  d="M34.986 17.537c0 2.937.017 5.874 0 8.812-.03 3.961-2.587 7.288-6.4 8.326a8.212 8.212 0 0 1-2.088.302c-6.001.022-12.003.039-18.004 0C4.143 34.95.483 31.549.063 27.229c-.035-.35-.057-.705-.057-1.055 0-5.783-.013-11.57 0-17.353.004-2.845 1.099-5.2 3.34-6.977A8.246 8.246 0 0 1 8.564.018c5.957-.017 11.91-.03 17.868 0 4.422.022 8.06 3.384 8.501 7.788.04.372.057.748.057 1.125.005 2.871 0 5.739 0 8.61l-.004-.004ZM17.494 31.5h8.645c1.221 0 2.346-.31 3.318-1.064 1.388-1.068 2.027-2.517 2.036-4.242.013-3.099 0-6.194 0-9.293 0-2.71.013-5.42 0-8.129-.018-2.972-2.206-5.244-5.148-5.257-5.9-.03-11.797-.026-17.698 0-2.902.013-5.13 2.254-5.144 5.157-.026 5.887-.013 11.775 0 17.663a5 5 0 0 0 1.09 3.169c1.077 1.366 2.513 1.987 4.229 1.992 2.894.008 5.787 0 8.68 0l-.008.004Z" /> <path  d="M17.507 8.756c4.903.008 8.75 3.874 8.733 8.776-.018 4.886-3.874 8.725-8.764 8.716-4.898-.009-8.75-3.874-8.733-8.777.013-4.885 3.874-8.724 8.764-8.715Zm5.24 8.737c-.005-2.955-2.285-5.24-5.244-5.244-2.968-.004-5.267 2.29-5.262 5.262 0 2.954 2.29 5.24 5.244 5.244 2.968.004 5.266-2.294 5.262-5.262ZM27.99 8.764c-.004.968-.813 1.765-1.772 1.747a1.762 1.762 0 0 1-1.73-1.76c.005-.967.815-1.764 1.774-1.746A1.761 1.761 0 0 1 27.99 8.76v.005Z" /></svg>
              </a>
              <a className="social_icon" href="https://www.tiktok.com/@bovenmedia?lang=en" target="_blank" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 31 35"> <path  d="M16.294 0H22c.084.593.137 1.181.257 1.756.813 3.925 4.296 6.845 8.34 6.98.314.01.407.093.403.41-.018 1.646-.009 3.288-.009 4.934v.369c-3.32-.013-6.259-1.027-8.933-2.994v.421c.013 3.995.018 7.995.04 11.99.013 2.178-.465 4.224-1.684 6.046-2.387 3.56-5.76 5.282-10.065 5.07-4.695-.228-8.7-3.463-9.96-7.955C-1.191 21.394 2.19 15.41 7.86 13.8a11.637 11.637 0 0 1 4.433-.395c.058.004.116.013.173.026l.225.044v5.73c-.508-.066-.99-.172-1.476-.185-2.802-.066-5.066 1.936-5.353 4.716-.27 2.581 1.605 5.018 4.261 5.536 3.037.588 6.007-1.712 6.144-4.777.04-.834.022-1.668.022-2.503.005-7.16.01-14.321.01-21.482V0h-.005Z" /> </svg>
              </a>
            </div>
          </div>
        </div>
      </nav>

      {/* ==================================  scroll Menu starts ========================================= */}
      <div className={`hamburger_menu ${scrolled ? "active" : ""}`} >
          <div className="cricle"  onClick={toggleMenu}>
            <div>
              <div className={`bar bar1 ${isOpen ? "open" : ""}`}></div>
              <div className={`bar bar2 ${isOpen ? "open" : ""}`}></div>
              <div className={`bar bar3 ${isOpen ? "open" : ""}`}></div>
            </div>
          </div>

        <div className="menu">
          <div className="pattern" style={{ backgroundImage: `url(${pattern})` }}></div>
          <ul>
            <li className="nav-link">
              <Link to="/" onClick={toggleMenu}>
                <div className="logo">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 82 50"> {" "} <g clipPath="url(#a)"> {" "} <path d="M81.817 19.744 54.525 0 40.907 9.85 27.292 0 0 19.744c.016 7.75 0 30.255 0 30.255h82s-.151-22.526-.183-30.255Zm-33.941 3.374v20.154H33.94V23.118l6.968-5.04 6.968 5.04ZM6.709 43.272V23.118L27.292 8.227l7.93 5.737-7.99 5.78v23.528H6.71Zm47.875 0V19.744l-7.989-5.78 7.93-5.737 20.584 14.89v20.155H54.584Z" />{" "} </g>{" "} <defs> {" "} <clipPath id="a"> {" "} <path d="M0 0h82v50H0z" />{" "} </clipPath>{" "} </defs> </svg>
                </div>
              </Link>
            </li>
            <li className="nav-link">
              <Link className="list_link" to={"/#our-work"} onClick={toggleMenu}>Our Work</Link>
            </li>
            <li className="nav-link">
              <Link className="list_link" to={"/#our-packages"} onClick={toggleMenu}>Our packages</Link>
            </li>
            <li className="nav-link social_link">
              <a className="social_icon" href="https://www.instagram.com/bovenmedia/" target="_blank" rel="noopener noreferrer" onClick={toggleMenu}>
                <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 35 35"> <path  d="M34.986 17.537c0 2.937.017 5.874 0 8.812-.03 3.961-2.587 7.288-6.4 8.326a8.212 8.212 0 0 1-2.088.302c-6.001.022-12.003.039-18.004 0C4.143 34.95.483 31.549.063 27.229c-.035-.35-.057-.705-.057-1.055 0-5.783-.013-11.57 0-17.353.004-2.845 1.099-5.2 3.34-6.977A8.246 8.246 0 0 1 8.564.018c5.957-.017 11.91-.03 17.868 0 4.422.022 8.06 3.384 8.501 7.788.04.372.057.748.057 1.125.005 2.871 0 5.739 0 8.61l-.004-.004ZM17.494 31.5h8.645c1.221 0 2.346-.31 3.318-1.064 1.388-1.068 2.027-2.517 2.036-4.242.013-3.099 0-6.194 0-9.293 0-2.71.013-5.42 0-8.129-.018-2.972-2.206-5.244-5.148-5.257-5.9-.03-11.797-.026-17.698 0-2.902.013-5.13 2.254-5.144 5.157-.026 5.887-.013 11.775 0 17.663a5 5 0 0 0 1.09 3.169c1.077 1.366 2.513 1.987 4.229 1.992 2.894.008 5.787 0 8.68 0l-.008.004Z" /> <path  d="M17.507 8.756c4.903.008 8.75 3.874 8.733 8.776-.018 4.886-3.874 8.725-8.764 8.716-4.898-.009-8.75-3.874-8.733-8.777.013-4.885 3.874-8.724 8.764-8.715Zm5.24 8.737c-.005-2.955-2.285-5.24-5.244-5.244-2.968-.004-5.267 2.29-5.262 5.262 0 2.954 2.29 5.24 5.244 5.244 2.968.004 5.266-2.294 5.262-5.262ZM27.99 8.764c-.004.968-.813 1.765-1.772 1.747a1.762 1.762 0 0 1-1.73-1.76c.005-.967.815-1.764 1.774-1.746A1.761 1.761 0 0 1 27.99 8.76v.005Z" /></svg>
              </a>
            </li>
            <li className="nav-link">
              <a className="social_icon" href="https://www.tiktok.com/@bovenmedia?lang=en" target="_blank" rel="noopener noreferrer" onClick={toggleMenu}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 31 35"> <path  d="M16.294 0H22c.084.593.137 1.181.257 1.756.813 3.925 4.296 6.845 8.34 6.98.314.01.407.093.403.41-.018 1.646-.009 3.288-.009 4.934v.369c-3.32-.013-6.259-1.027-8.933-2.994v.421c.013 3.995.018 7.995.04 11.99.013 2.178-.465 4.224-1.684 6.046-2.387 3.56-5.76 5.282-10.065 5.07-4.695-.228-8.7-3.463-9.96-7.955C-1.191 21.394 2.19 15.41 7.86 13.8a11.637 11.637 0 0 1 4.433-.395c.058.004.116.013.173.026l.225.044v5.73c-.508-.066-.99-.172-1.476-.185-2.802-.066-5.066 1.936-5.353 4.716-.27 2.581 1.605 5.018 4.261 5.536 3.037.588 6.007-1.712 6.144-4.777.04-.834.022-1.668.022-2.503.005-7.16.01-14.321.01-21.482V0h-.005Z" /> </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* ==================================  scroll Menu ends ========================================= */}
    </header>
  );
}

export default Header;
