import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./scss/App.scss";

import Footer from "./components/Footer";
import Home from "./components/Home";
import CheckoutPage from "./components/CheckoutPage";
import AppContextProvider from "./context/AppContextProvider";
import ScrollToTop from "./components/ScrollToTop";

import bgPattern from "./assets/images/boven-bkgd.svg";

function App() {
  return (
    <div className="App">
      <div className="bg_pattern" style={{ backgroundImage: `url(${bgPattern})` }}></div>
      <div className="transition_screen"></div>
      <AppContextProvider>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/checkout" component={CheckoutPage} />
          </Switch>
          <Footer />
        </Router>
      </AppContextProvider>
    </div>
  );
}

export default App;
