import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
// import YouTube from 'react-youtube';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger) 


const GalleryVideo = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const player = videoElement.internalPlayer;
    if (player && typeof player.pauseVideo === 'function') {
      player.pauseVideo();
    }

    gsap.to(videoElement, {
       scrollTrigger: {
          trigger: videoElement,
          start: 'top center',
          end: 'bottom center',
          toggleActions: 'play none none none',
          // scrub:true,
          // markers:true
       },
    });
 }, []);

  return (
    <div ref={videoRef} className="video_wrapper">
      <iframe 
        src="https://www.youtube.com/embed/EV97baFMH5o?rel=0&autoplay=1&mute=1&controls=0&&showinfo=0&playlist=EV97baFMH5o&loop=1" 
        title="BovenMedia 9000JaneStreetUnit410 MLS VIDEO" 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>
      </iframe>
    </div>
  );
};

export default GalleryVideo;
