import React from "react";

function Footer() {
  return (
    <footer>
      <h4>CONTACT US</h4>
      <div className="details">
        {/* <a href="tel:416.000.000">416.000.000</a> */}
        <a href="mailto:info@bovenmedia.ca">info@bovenmedia.ca</a>
      </div>
      <p>DESIGNED BY PUSH MARKETING GROUP INC.</p>
    </footer>
  );
}

export default Footer;
