import React from "react";
import SelectedItemsDisplay from "./SelectedItemsDisplay";
import ContactForm from "./ContactForm";
import Header from "./Header";


const CheckoutPage = () => {
  return (
    <div className="main_page">
      <div className="page_two_header">
        <Header />
      </div>
      {/* <SelectionPattern /> */}
      <SelectedItemsDisplay />
      <ContactForm />
    </div>
  );
};

export default CheckoutPage;
