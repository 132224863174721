import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === 'PUSH') {
        window.scrollTo(0, 0);
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [history]);

  return null;
}

export default withRouter(ScrollToTop);
