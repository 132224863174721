import React, { useState, useRef, useContext } from "react";
import emailjs from '@emailjs/browser';
import { GlobalDataContext } from "../context/AppContextProvider";


// Images
const ContactForm = () => {
  const { appData } = useContext(GlobalDataContext);
  const form = useRef();
  // State variables to store form data
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    streetAddress: "",
    city: "",
    province: "",
    postalCode: "",
    selectedVideo: appData.selectedVideo.title,
    selectedPackage:"",
    subsscriptionType: "",
  });

  // Function to handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };



  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_icjnsja',
        'template_b63qwwy',
        form.current,
        'Kt5TliwwUipBnv1dq'
      )
      .then(
        (result) => {
          // console.log(result.text);
          // Update the state to show the "OK" message
          setFormData({
            firstName: "",
            lastName: "",
            companyName: "",
            email: "",
            phoneNumber: "",
            streetAddress: "",
            city: "",
            province: "",
            postalCode: "",
            selectedVideo: appData.selectedVideo.title,
            selectedPackage:appData.selectedPlan.title,
            subsscriptionType: appData.selectedType,
          });

          // console.log("Atmiya", formData);

          // Show the "OK" message
          document.querySelector(".msg").textContent = "Email Sent Successfully!";
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  

  return (
    <div className="contact_section">
      <div className="wraper">
        <div className="nots">
          <p className="note">
            We appreciate your interest in our real estate video and photo
            marketing services. <br />Please note that the information collected through
            this form is for service customization and invoicing purposes.
          </p>
          <h3>
            Payments will be processed separately <br />after service options are
            confirmed.
          </h3>
        </div>
        <section className="contact_block">
          <h2 className="title">YOUR INFORMATION:</h2>

          <form className="contact_form" ref={form} onSubmit={sendEmail}>
                <input style={{height: '100px', background: 'red', width:'100%', fontSize: '20px'}} type="hidden" id="selectedVideo" name="selectedVideo" value={appData.selectedVideo.title} />
                <input style={{height: '100px', background: 'red', width:'100%', fontSize: '20px'}} type="hidden" id="selectedPlan" name="selectedPlan" value={appData.selectedPlan.title} />
                <input style={{height: '100px', background: 'red', width:'100%', fontSize: '20px'}} type="hidden" id="selectedType" name="selectedType" value={appData.selectedType} />
                 <input style={{height: '100px', background: 'red', width:'100%', fontSize: '20px'}} type="hidden" id="selectedAddsOn"
                    name="selectedAddsOn"
                    value={appData.priceAddsOn.reduce(
                      (accumulator, currentValue) =>
                        accumulator + (currentValue.checked ? currentValue.label + ', ' : ''),
                      ''
                    )}
                  /> 
                  <input style={{height: '100px', background: 'red', width:'100%', fontSize: '20px'}} type="hidden" id="cost" name="cost"
                    value={appData.total +
                      (appData.selectedType === "monthly"
                        ? appData.selectedPlan?.monthlyPrice
                        : appData.selectedPlan?.oneTimePayment)}
                  /> 
            <div className="form_group">
              <div className="form-field">
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  placeholder="first name"
                  required
                />
              </div>
              <div className="form-field">
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  placeholder="last name"
                  required
                />
              </div>
            </div>

            <div className="form-field">
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
                placeholder="company/business name"
              />
            </div>

            <div className="form-field">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="email"
                required
              />
            </div>

            <div className="form-field">
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                placeholder="phone number"
              />
            </div>

            <h4>Billing address</h4>
            <div className="form-field">
              <input
                type="text"
                id="streetAddress"
                name="streetAddress"
                value={formData.streetAddress}
                onChange={handleInputChange}
                placeholder="street address"
                required
              />
            </div>

            <div className="form_group">
              <div className="form-field">
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  placeholder="city"
                  required
                />
              </div>

              <div className="form-field">
                <input
                  type="text"
                  id="province"
                  name="province"
                  value={formData.province}
                  onChange={handleInputChange}
                  placeholder="province"
                  required
                />
              </div>

              <div className="form-field">
                <input
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleInputChange}
                  placeholder="postal"
                  required
                />
              </div>
            </div>

            

            <button className="submit_btn" type="submit">
              Submit
            </button>
            <p className="msg"></p>
          </form>
        </section>
      </div>
    </div>
  );
};

export default ContactForm;
