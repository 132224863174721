import React, { useContext, useState } from "react";
import { GlobalDataContext } from "../context/AppContextProvider";

// Images
import goldCoin from "../assets/images/gold_coin.svg";

const SelectedItemsDisplay = ( {updateFormData} ) => {
  // const [total, setTotal] = useState([]);
  const { appData, setAppData } = useContext(GlobalDataContext);

  // const prc = [
  //   { label: "INTRODUCTION VIDEO", cost: 150, checked: false },
  //   { label: "ANIMATED LOGO", cost: 100, checked: false },
  //   { label: "360 TOUR", cost: 150, checked: false },
  //   // { label: "SOCIAL MEDIA INFO VIDEO", cost: 150, checked: false },
  // ];

  const handleCheckboxChange = (item) => {
    const priceAdds = appData.priceAddsOn;
    priceAdds.map(e=> e === item ? e.checked = !e.checked : null);
    const sumWithInitial = priceAdds.reduce(
      (accumulator, currentValue) =>
        accumulator + (currentValue.checked ? currentValue.cost : 0),
      0
    );
    setAppData({
      ...appData,
      priceAddsOn: priceAdds,
      total: sumWithInitial 
    })
    // const result = appData.priceAddsOn.map((el) =>
    //   el.label === item.label ? { ...el, checked: !item.checked } : el
    // );
   
    // setTotal(sumWithInitial);
  };

  const onChangesDp = (e) => {
    const item = appData.packages.find(
      (item) => item.id === parseInt(e.target.value)
    );
    setAppData({
      ...appData,
      selectedPlan: { ...item, type: "monthly" },
    });
    // console.log(e.target.value);
  };

  const onChangesMonthType = (e) => {
    setAppData({
      ...appData,
      selectedType: e.target.value,
      selectedPlan: { ...appData.selectedPlan, type: e.target.value },
    });
  };

  const onSelectItem = (item) => {
    setAppData({
      ...appData,
      selectedVideo: item,
    });
  };

  return (
    <div className="selection_block">
      <div className="wraper">
        {/* ======================== your Selection =========================== */}
        {/* ======================== video Selection =========================== */}
        <section className="your_selection radio_selection">
          <div className="gold_coin">
            <img src={goldCoin} alt="goldCoin" />
          </div>
          <h3 className="title">YOUR SELECTION:</h3>
          <div className="radio_group">
            {appData.videoList.map((videoItem) => (
              <label key={videoItem.id}>
                <input
                  type="radio"
                  value={videoItem.id}
                  checked={appData.selectedVideo.id === videoItem.id}
                  onChange={(e) => onSelectItem(videoItem)}
                />
                {videoItem.title}
              </label>
            ))}
          </div>
        </section>
        {/* ======================== package Selection =========================== */}
        <section className="your_selection radio_selection">
          <div className="radio_group">
            {appData.packages.length > 0 &&
              appData.packages.map((packageItem, index) => (
                <label key={index}>
                  <input
                    type="radio"
                    value={packageItem.id}
                    checked={appData.selectedPlan?.id === packageItem.id}
                    onChange={onChangesDp}
                  />
                  {packageItem.title}
                </label>
              ))}
          </div>
        </section>

        {/* ======================== Select Subscription =========================== */}
        <section className="subscription_type radio_selection">
          <h3 className="title">SUBSCRIPTION TYPE:</h3>
          <div className="radio_group sub_drop_down">
            <label>
              <input
                type="radio"
                value="monthly"
                checked={appData.selectedType === "monthly"}
                onChange={onChangesMonthType}
              />
              MONTHLY Payment
            </label>
            <label>
              <input
                type="radio"
                value="oneTime"
                checked={appData.selectedType === "oneTime"}
                onChange={onChangesMonthType}
              />
              ONE-TIME PAYMENT
            </label>
          </div>
        </section>

        {/* ======================== Add ons =========================== */}
        <section className="add_ons">
          <h3 className="title">DON’T FORGET YOUR ADD-ONS:</h3>
          <ul>
            {appData.priceAddsOn &&
              appData.priceAddsOn.map((item, index) => (
                <li key={index}>
                  <label>
                    <div className="flex">
                      <input
                        type="checkbox"
                        checked={item.checked}
                        onChange={() => handleCheckboxChange(item)}
                      />
                      <span>{item.label}</span>
                    </div>
                    <span>${item.cost}</span>
                  </label>
                </li>
              ))}
          </ul>
        </section>

        {/* ======================== Selection Total =========================== */}
        <section className="selection_total">
          <h3 className="title">SELECTION TOTAL: </h3>
          <div className="total_box">
            <div className="cost">
              ${" "}
              {appData.total +
                (appData.selectedType === "monthly"
                  ? appData.selectedPlan?.monthlyPrice
                  : appData.selectedPlan?.oneTimePayment)}
            </div>
            <div className="duration">
              <span>
                [
                {appData.selectedType === "monthly"
                  ? "monthly payments"
                  : "ONE TIME Payment"}
                ]
              </span>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SelectedItemsDisplay;
