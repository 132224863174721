import React, { useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { GlobalDataContext } from "../context/AppContextProvider";

import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

// Images
import or from "../assets/images/OR.svg";
import blankCoin from "../assets/images/blank-coin.svg";
import cart from "../assets/images/cart.svg";

const ProductSelection = () => {
  const { appData, setAppData } = useContext(GlobalDataContext);
  const navigate = useHistory();
  const checkoutBtnClick = (selectedItem, type) => {
    setAppData({
      ...appData,
      selectedPlan: { ...selectedItem },
      selectedType: type,
    });
    navigate.push("/checkout");
  };

  //==================================================== gsap Animation ====================================================
  gsap.registerPlugin(ScrollTrigger);

  const packageBlock = useRef();

  useGSAP(
    () => {
      const fadeRight = gsap.utils.toArray(".or");
      fadeRight.forEach((box) => {
        gsap.from(box, {
          y: -50,
          opacity: 0,
          duration: 1,
          rotate: -33,
          ease: "power3.out",
          scrollTrigger: {
            trigger: box,
            start: "center 85%",
            // markers:true
          },
        });
      });
    },
    { scope: packageBlock }
  );

  const titleAnimation = (titleSelector) => {
    const titleBox = document.querySelector(titleSelector);

    if (!titleBox) {
      console.error(`Title box not found for selector: ${titleSelector}`);
      return null;
    }

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: titleBox,
        start: "top center",
        end: "bottom center",
        // markers: true,
      },
    });

    //  Select only the child elements within the titleBox
    const subBox = titleBox.querySelector(".box");
    const text = titleBox.querySelector(".text");

    if (!subBox || !text) {
      console.error(
        `Sub box or text not found within title box: ${titleSelector}`
      );
      return null;
    }

    // gsap.set([subBox, text], { opacity: 1, scale: 1 });
    // gsap.set([text], { x:1 });

    tl.to(subBox, { opacity: 1, scaleY: 1, ease: "power1.out" }).to(
      text,
      { opacity: 1, x: 1, ease: "power1.out" },
      "=-.2"
    );

    return tl;
  };

  const onSelectItem = (item) => {
    setAppData({
      ...appData,
      selectedVideo: item,
    });
  };

  useEffect(() => {
    // Call the function for each title by passing the title selector
    titleAnimation(".titleBoxSix");
    titleAnimation(".titleBoxSeven");
    // Add more titles if needed
  }, []);

  return (
    <div id="our_packages" className="package_section">
      <div id="our-packages" className="package_wrapper">
        {/* -------------------------------- Package 1 -------------------------------- */}
        <section className="step_one_videos_selection">
          <div className="title_box yellow titleBoxSix">
            <div className="sub_box">
              <div className="box"></div>
              <div className="text">
                <h3 className="under_line">Step 1 - select type of video</h3>
              </div>
            </div>
          </div>
          <div className="selection_row fade_up">
            {appData.videoList.map((videoItem) => (
              <div
                className={
                  `video_block ` +
                  (appData.selectedVideo.id === videoItem.id ? "selected" : "")
                }
                key={videoItem.id}
              >
                <h3>{videoItem.title}</h3>
                <p>{videoItem.credit}</p>
                <ul>
                  {videoItem.points?.map((e) => (
                    <li className="video_info">{e}</li>
                  ))}
                </ul>
                <label>
                  <input
                    type="radio"
                    name="videoSelection"
                    checked={appData.selectedVideo.id === videoItem.id}
                    onChange={(e) => onSelectItem(videoItem)}
                  />
                </label>
              </div>
            ))}
          </div>
        </section>
        <section className="package_block monthly_packages" ref={packageBlock}>
          <div className="title_box yellow titleBoxSeven">
            <div className="sub_box">
              <div className="box"></div>
              <div className="text">
                <h3 className="under_line">Step 2 - select number of credits</h3>
              </div>
            </div>
          </div>
          <ul className="package_wrap">
            {appData.packages.length > 0 &&
              appData.packages.map((packageItem, index) =>
                index === 0 ? (
                  <>
                    <li className="package1 fade_up" key={index}>
                      <div className="step_package">
                        <div className="pkg_info">
                          <div className="blank_coin">
                            {" "}
                            <img src={blankCoin} alt="blankCoin" />{" "}
                            <span>{packageItem.packageNumber}</span>{" "}
                          </div>
                          <h4 className="pkg_name"> {packageItem.name} </h4>
                          <div className="credit">{packageItem.credits}</div>
                          <div className="pkg_amount">
                            ${packageItem.monthlyPrice}
                          </div>
                          <button
                            className="cta"
                            onClick={(e) =>
                              checkoutBtnClick(packageItem, "monthly")
                            }
                          >
                            <span>
                              {" "}
                              <img src={cart} alt="cart" />{" "}
                            </span>
                            PURCHASE NOW
                          </button>
                        </div>
                      </div>
                    </li>
                    <div className="title_block">
                      <div className="or">
                        <img src={or} alt="or" />
                      </div>
                      <h3 className="fade_up">
                        SAVE MORE WHEN YOU PURCHASE PACKAGES WITH MULTIPLE
                        CREDITS. CHOOSE TO PAY MONTHLY OR PAY UPFRONT
                      </h3>
                    </div>
                  </>
                ) : (
                  // ============================================================================================

                  <li className="package_list fade_up" key={packageItem.id}>
                    <div className="blank_coin">
                      {" "}
                      <img src={blankCoin} alt="blankCoin" />{" "}
                      <span>{packageItem.packageNumber}</span>{" "}
                    </div>
                    <h2>{packageItem.name}</h2>
                    <div className="credit">{packageItem.credits}</div>
                    <div className="cost">
                      ${packageItem.monthlyPrice}
                      <span>/mo</span>
                    </div>
                    <p className="month_time">{packageItem.monthTime}</p>
                    <button
                      className="cta"
                      onClick={(e) => checkoutBtnClick(packageItem, "monthly")}
                    >
                      <span>
                        {" "}
                        <img src={cart} alt="cart" />{" "}
                      </span>
                      PURCHASE NOW
                    </button>
                  </li>
                )
              )}
          </ul>
          <h3 className="fade_up or_text">OR PAY UPFRONT AND SAVE MORE</h3>
        </section>

        {/* -------------------------------- yearly Packages -------------------------------- */}
        <section className="package_block yearly_packages">
          <ul className="package_wrap fade_up">
            {appData.packages.length > 0 &&
              appData.packages.map((packageItem, index) =>
                index > 0 ? (
                  <li className="package_list" key={"package" + packageItem.id}>
                    <div className="blank_coin">
                      {" "}
                      <img src={blankCoin} alt="blankCoin" />{" "}
                      <span>{packageItem.packageNumber}</span>{" "}
                    </div>
                    <h2>{packageItem.name}</h2>
                    <div className="credit">{packageItem.credits}</div>
                    <div className="cost">${packageItem.oneTimePayment}</div>
                    <button
                      className="cta"
                      onClick={(e) => checkoutBtnClick(packageItem, "yearly")}
                    >
                      <span>
                        {" "}
                        <img src={cart} alt="cart" />{" "}
                      </span>
                      PURCHASE NOW
                    </button>
                  </li>
                ) : null
              )}
          </ul>
        </section>
      </div>
    </div>
  );
};

export default ProductSelection;
